import axiosInstance from "../services/axiosInstance";

import { IAgentsConfig } from "../redux/ducks/ducks.types";
import { IResetPasswordFormValues } from "../containers/SettingsSecurity/SettingsSecurity";
import { IDay } from "../containers/SettingsSchedule/SettingsSchedule.types";

type ProgressHandler = (progressEvent: ProgressEvent) => void;

const logoutUser = () => axiosInstance.post("auth/logout");

const getUser = () => {
  return axiosInstance.get("user");
};

const updateUser = (user: {
  url?: string;
  name: string;
  type?: number;
  phone?: string;
  description?: string;
}) => {
  const UserInfo: {
    name: string;
    description: string;
    phone?: string;
    url?: string;
  } = {
    name: user.name ? user.name : "",
    description: user.description ? user.description : "",
  };

  if (user.type === 2) {
    UserInfo.phone = user.phone || "";
    UserInfo.url = user.url || "";
  }

  return axiosInstance.patch("user/update", UserInfo);
};

const updateUserAvatar = (img: any, onUploadProgress?: ProgressHandler) => {
  const UserAvatar = new FormData();

  typeof img !== "string" && UserAvatar.set("img", img || "");

  return axiosInstance.post("user/update-avatar", UserAvatar, {
    onUploadProgress,
  });
};

const deleteUser = () => {
  return axiosInstance.delete("user/delete");
};

const changePassword = (values: IResetPasswordFormValues) => {
  const Password: {
    old_password: string;
    new_password: string;
    new_password_confirmation: string;
  } = {
    old_password: values.old_password,
    new_password: values.new_password,
    new_password_confirmation: values.password_confirmation,
  };

  return axiosInstance.put("user/update-password", Password);
};

const getAgencies = (offset?: number, search?: string) => {
  const apiConfig: {
    params: {
      limit: number;
      offset?: number;
      search?: string;
    };
  } = {
    params: {
      limit: 20,
    },
  };

  if (offset) {
    apiConfig.params.offset = offset;
  }

  if (search) {
    apiConfig.params.search = search;
  }

  return axiosInstance.get("agent/subscription/followers", apiConfig);
};

const getAgenciesCount = () => {
  return axiosInstance.get("agent/subscription/count-followers");
};

const getAgents = (config: IAgentsConfig) => {
  const apiConfig: {
    params: {
      limit: number;
      search?: string;
      is_active?: number;
      offset?: number;
    };
  } = {
    params: {
      limit: 20,
    },
  };

  if (config.search) {
    apiConfig.params.search = config.search;
  }
  if (config.filterStatus) {
    apiConfig.params.is_active = config.filterStatus === 1 ? 1 : 0;
  }
  if (config.offset) {
    apiConfig.params.offset = config.offset;
  }

  return axiosInstance.get("agency/subscription/follows", apiConfig);
};

const getInvites = (offset?: number) => {
  const apiConfig: {
    params: {
      limit: number;
      offset?: number;
    };
  } = {
    params: {
      limit: 20,
    },
  };

  if (offset) {
    apiConfig.params.offset = offset;
  }

  return axiosInstance.get("agency/subscription/requests", apiConfig);
};

const getInvitesCount = () => {
  return axiosInstance.get("agency/subscription/count-requests");
};

const getAgentsCount = () => {
  return axiosInstance.get("agency/subscription/count-follows");
};

const sendInvite = (email: string) => {
  const Invite = new FormData();

  Invite.set("email", email);

  return axiosInstance.post("agency/subscription/send-request", Invite);
};

const unsubscribeAgent = (agent_id: string) => {
  return axiosInstance.delete(`agency/subscription/unsubscribe/${agent_id}`);
};

const unsubscribeAgency = (agency_id: string) => {
  return axiosInstance.delete(`agent/subscription/unsubscribe/${agency_id}`);
};

const getNotificationsCount = () => {
  return axiosInstance.get("user/notification/total");
};

const getNotifications = (offset?: number, limit?: number) => {
  const apiConfig: {
    params: {
      limit?: number;
      offset?: number;
    };
  } = {
    params: {
      limit: 20,
    },
  };

  if (offset) {
    apiConfig.params.offset = offset;
  }
  if (limit) {
    apiConfig.params.limit = limit;
  }

  return axiosInstance.get("user/notification/all", apiConfig);
};

const acceptInviteNotification = (id: number) => {
  return axiosInstance.post(`agent/subscription/accept/${id}`);
};

const declineInviteNotification = (id: number) => {
  return axiosInstance.patch(`agent/subscription/decline/${id}`);
};

const readNotification = (id: number) => {
  return axiosInstance.delete(`user/notification/read/${id}`);
};

const deleteEventRequest = (id: number) => {
  return axiosInstance.delete(`agency/schedule/work-record-request/${id}`);
};

const deleteInvite = (id: number) => {
  return axiosInstance.delete(`agency/subscription/invite/${id}`);
};

const getWorkTime = () => {
  return axiosInstance.get("agent/settings/schedule/work-time");
};

const setWorkTime = (config: { mode: string; times: IDay | IDay[] }) => {
  const apiConfig: {
    mode: string;
    times: string;
  } = {
    mode: config.mode,
    times: JSON.stringify(config.times),
  };

  return axiosInstance.patch(
    "agent/settings/schedule/set-work-time",
    apiConfig
  );
};

const getAgentSchedule = (date: number) => {
  return axiosInstance.get("agent/schedule", { params: { date } });
};

const getAgencySchedule = (date: number, offset?: number, search?: string) => {
  const config: {
    params: {
      date: number;
      limit: number;
      offset?: number;
      search?: string;
    };
  } = {
    params: {
      date,
      limit: 20,
    },
  };

  if (offset) {
    config.params.offset = offset;
  }

  if (search) {
    config.params.search = search;
  }

  return axiosInstance.get("agency/schedule", config);
};

const addEvent = (
  config: {
    id: null | number;
    start: number;
    end: number;
    agencyId: number;
    type: string;
    description: string;
  },
  action?: string
) => {
  const WorkRecord = new FormData();

  WorkRecord.set("end", JSON.stringify(config.end));
  WorkRecord.set("type", config.type);
  WorkRecord.set("start", JSON.stringify(config.start));
  config.description && WorkRecord.set("description", config.description);
  config.agencyId &&
    WorkRecord.set("agencyId", JSON.stringify(config.agencyId));

  let configApi: {
    id: number | null;
    start: number;
    end: number;
    agencyId?: number;
    type: string;
    description?: string;
  } = {
    id: config.id,
    start: config.start,
    end: config.end,
    type: config.type,
  };

  if (config.description) {
    configApi.description = config.description;
  }

  if (config.agencyId != 0) {
    configApi.agencyId = config.agencyId;
  }

  return action === "update"
    ? axiosInstance.put(
        `agent/schedule/update-work-record/${config.id}`,
        configApi
      )
    : axiosInstance.post("agent/schedule/add-work-record", WorkRecord);
};

const deleteEvent = (eventId: number) => {
  return axiosInstance.delete(`agent/schedule/drop-work-record/${eventId}`);
};

const changeWorkingStatus = (status: 0 | 1) => {
  // const WorkingStatus = new FormData();

  // WorkingStatus.set("is_available", JSON.stringify(status));

  const WorkingStatus = {
    is_available: JSON.stringify(status),
  };

  return axiosInstance.patch(
    "agent/settings/schedule/set-working-status",
    WorkingStatus
  );
};

const addEventRequest = (config: {
  agent_id: number;
  from: string;
  to: string;
  description?: string;
}) => {
  const Request = new FormData();

  Request.set("agent_id", JSON.stringify(config.agent_id));
  Request.set("from", config.from);
  Request.set("to", config.to);
  config.description && Request.set("description", config.description);

  return axiosInstance.post(
    "agency/schedule/create-work-record-request",
    Request
  );
};

const acceptWorkRequest = (eventId: number) => {
  return axiosInstance.post(`agent/schedule/accept-work-request/${eventId}`);
};

const declineWorkRequest = (eventId: number) => {
  return axiosInstance.delete(`agent/schedule/decline-work-request/${eventId}`);
};

const setNickname = (agentId: number, nickname: string) => {
  const config: {
    agent_id: number;
    nickname: string;
  } = {
    agent_id: agentId,
    nickname: nickname,
  };

  if (nickname) {
    return axiosInstance.put("agency/nickname/agent", config);
  }

  return axiosInstance.delete(`agency/nickname/agent/${agentId}`);
};

const API = {
  logoutUser,
  getUser,
  getAgencies,
  getAgenciesCount,
  updateUser,
  deleteUser,
  changePassword,
  getAgents,
  getInvites,
  getInvitesCount,
  sendInvite,
  unsubscribeAgent,
  getNotificationsCount,
  getNotifications,
  acceptInviteNotification,
  declineInviteNotification,
  deleteInvite,
  getWorkTime,
  setWorkTime,
  getAgentSchedule,
  addEvent,
  deleteEvent,
  changeWorkingStatus,
  updateUserAvatar,
  getAgentsCount,
  getAgencySchedule,
  readNotification,
  addEventRequest,
  deleteEventRequest,
  acceptWorkRequest,
  declineWorkRequest,
  setNickname,
  unsubscribeAgency,
};

export default API;
