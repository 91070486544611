import { Dispatch } from "redux";

import API from "../../api/api";
import { normalize } from "../../utils";
import { IAgencyScheduleState } from "./ducks.types";
import { IAction, IEvent } from "../../interfaces/interfaces";
import isSameDay from "../../utils/isSameDay";
import getUtcDateMillisecFromString from "../../utils/getUtcDateMillisecFromString";
import getNewDateFromString from "../../utils/getNewDateFromString";

export const GET_AGENCY_SCHEDULE = "GET_AGENCY_SCHEDULE";
export const UPDATE_AGENCY_SCHEDULE = "UPDATE_AGENCY_SCHEDULE";
export const SET_PENDING_TRUE = "SET_PENDING_TRUE";

export const ADD_EVENT_AGENCY_SCHEDULE = "ADD_EVENT_AGENCY_SCHEDULE";
export const UPDATE_EVENT_AGENCY_SCHEDULE = "UPDATE_EVENT_AGENCY_SCHEDULE";
export const DELETE_EVENT_AGENCY_SCHEDULE = "DELETE_EVENT_AGENCY_SCHEDULE";
export const UPDATE_NICKNAME_AGENCY_SCHEDULE =
  "UPDATE_NICKNAME_AGENCY_SCHEDULE";
export const ADD_CHANGED_AGENT_ID_AGENCY_SCHEDULE =
  "ADD_CHANGED_AGENT_ID_AGENCY_SCHEDULE";
export const REMOVE_CHANGED_AGENT_ID_AGENCY_SCHEDULE =
  "REMOVE_CHANGED_AGENT_ID_AGENCY_SCHEDULE";
export const UPDATE_WORK_TIME_SETTINGS = "UPDATE_WORK_TIME_SETTINGS";
export const DELETE_AGENT_AGENCY_SCHEDULE = "DELETE_AGENT_AGENCY_SCHEDULE";

const initialState: IAgencyScheduleState = {
  pending: true,
  ids: [],
  entities: {},
  workTimes: null,
  filterDate: 0,
  changedIds: [],
};

export const getAgencySchedule =
  (date: number, offset: number, search?: string) => (dispatch: Dispatch) => {
    offset == 0 && dispatch({ type: SET_PENDING_TRUE });

    return API.getAgencySchedule(date, offset, search)
      .then(({ data }) => {
        dispatch({
          type: offset ? UPDATE_AGENCY_SCHEDULE : GET_AGENCY_SCHEDULE,
          payload: {
            filter_date: date,
            work_times: data.work_times,
            ...normalize(data.agents),
          },
        });
      })
      .catch(console.error);
  };

export const addEventAgencySchedule =
  (event: IEvent, work_time: { from: number; to: number }) =>
  (dispatch: Dispatch) => {
    dispatch({
      type: ADD_EVENT_AGENCY_SCHEDULE,
      payload: { event, work_time },
    });
  };

export const updateEventAgencySchedule =
  (event: IEvent, work_time: { from: number; to: number }) =>
  (dispatch: Dispatch) => {
    dispatch({
      type: UPDATE_EVENT_AGENCY_SCHEDULE,
      payload: { event, work_time },
    });
  };

export const deleteEventAgencySchedule =
  (event: IEvent, work_time: { from: number; to: number }) =>
  (dispatch: Dispatch) => {
    dispatch({
      type: DELETE_EVENT_AGENCY_SCHEDULE,
      payload: { event, work_time },
    });
  };

export const updateNicknameAgencySchedule =
  (id: number, nickname: string | null) => (dispatch: Dispatch) => {
    dispatch({
      type: UPDATE_NICKNAME_AGENCY_SCHEDULE,
      payload: { id, nickname },
    });
  };

export const removeChangedAgentIdAgencySchedule =
  (id: number) => (dispatch: Dispatch) => {
    dispatch({
      type: REMOVE_CHANGED_AGENT_ID_AGENCY_SCHEDULE,
      payload: { id },
    });
  };

export const addChangedAgentIdAgencySchedule =
  (id: number) => (dispatch: Dispatch) => {
    dispatch({
      type: ADD_CHANGED_AGENT_ID_AGENCY_SCHEDULE,
      payload: { id },
    });
  };

export const updateWorkTimeSettings =
  (id: number, workTimeSchedule: { day?: number; from: string; to: string }) =>
  (dispatch: Dispatch) => {
    dispatch({
      type: UPDATE_WORK_TIME_SETTINGS,
      payload: { id, workTimeSchedule },
    });
  };

export const deleteAgentAgencySchedule =
  (id: number) => (dispatch: Dispatch) => {
    dispatch({
      type: DELETE_AGENT_AGENCY_SCHEDULE,
      payload: { id },
    });
  };

const getUpdatedAgentWorkTime = (
  firstEventFrom: number | null,
  lastEventTo: number | null,
  workTimeAgentScheduleFrom: number,
  workTimeAgentScheduleTo: number
) => {
  const updatedAgentWorkTime = {
    from:
      firstEventFrom && workTimeAgentScheduleFrom > firstEventFrom
        ? firstEventFrom
        : workTimeAgentScheduleFrom,
    to:
      lastEventTo && workTimeAgentScheduleTo < lastEventTo
        ? lastEventTo
        : workTimeAgentScheduleTo,
  };

  return updatedAgentWorkTime;
};

const getUpdatedCommonWorkTimes = (
  commonWorkTimes: { from: number; to: number } | null,
  agentWorkTime: { from: number; to: number }
) => {
  if (!commonWorkTimes) {
    return null;
  }

  let updatedCommonWorkTimes = null;

  if (
    commonWorkTimes &&
    (commonWorkTimes.from > agentWorkTime.from ||
      commonWorkTimes.to < agentWorkTime.to)
  ) {
    updatedCommonWorkTimes = { ...commonWorkTimes };
    if (commonWorkTimes.from > agentWorkTime.from) {
      updatedCommonWorkTimes.from = agentWorkTime.from;
    }
    if (commonWorkTimes.to < agentWorkTime.to) {
      updatedCommonWorkTimes.to = agentWorkTime.to;
    }
  }
  return updatedCommonWorkTimes;
};

const agencyScheduleReducer = (state = initialState, action: IAction) => {
  const { type, payload } = action;

  switch (type) {
    case SET_PENDING_TRUE: {
      return {
        ...state,
        pending: true,
      };
    }

    case GET_AGENCY_SCHEDULE: {
      return {
        ...state,
        workTimes: payload.work_times,
        ids: payload.ids,
        entities: payload.entities,
        pending: false,
        filterDate: payload.filter_date,
      };
    }

    case UPDATE_AGENCY_SCHEDULE: {
      const { work_times } = payload;
      let from;
      let to;

      if (state.workTimes) {
        from =
          work_times.from < state.workTimes.from
            ? work_times.from
            : state.workTimes.from;
        to =
          work_times.to > state.workTimes.to
            ? work_times.to
            : state.workTimes.to;
      }

      return {
        ...state,
        workTimes: { ...state.workTimes, from, to },
        ids: [...state.ids, ...payload.ids],
        entities: { ...state.entities, ...payload.entities },
        filterDate: payload.filter_date,
      };
    }

    case ADD_EVENT_AGENCY_SCHEDULE: {
      const { user_id } = payload.event;

      if (!isSameDay(state.filterDate, payload.event.from)) {
        return state;
      }

      if (!state.entities[user_id]) {
        //if id is not loaded
        return state;
      }

      const updatedWorkTimes = getUpdatedCommonWorkTimes(
        state.workTimes,
        payload.work_time
      );

      return {
        ...state,
        ...(updatedWorkTimes ? { workTimes: updatedWorkTimes } : {}),
        entities: {
          ...state.entities,
          [user_id]: {
            ...state.entities[user_id],
            work_time: payload.work_time,
            schedule_events: [
              ...state.entities[user_id].schedule_events,
              payload.event,
            ],
          },
        },
      };
    }

    case UPDATE_EVENT_AGENCY_SCHEDULE: {
      const { id, user_id } = payload.event;

      if (!isSameDay(state.filterDate, payload.event.from)) {
        return state;
      }

      if (!state.entities[user_id]) {
        return state;
      }

      const updatedWorkTimes = getUpdatedCommonWorkTimes(
        state.workTimes,
        payload.work_time
      );

      return {
        ...state,
        ...(updatedWorkTimes ? { workTimes: updatedWorkTimes } : {}),
        entities: {
          ...state.entities,
          [user_id]: {
            ...state.entities[user_id],
            work_time: payload.work_time,
            schedule_events: state.entities[user_id].schedule_events.map(
              (event) => (event.id === id ? payload.event : event)
            ),
          },
        },
      };
    }

    case DELETE_EVENT_AGENCY_SCHEDULE: {
      const { user_id } = payload.event;

      if (!isSameDay(state.filterDate, payload.event.from)) {
        return state;
      }

      return {
        ...state,
        entities: {
          ...state.entities,
          [user_id]: {
            ...state.entities[user_id],
            work_time: payload.work_time,
            schedule_events: state.entities[user_id].schedule_events.filter(
              (event) => event.id !== payload.event.id
            ),
          },
        },
      };
    }

    case UPDATE_NICKNAME_AGENCY_SCHEDULE: {
      return {
        ...state,
        entities: {
          ...state.entities,
          [payload.id]: {
            ...state.entities[payload.id],
            nickname: payload.nickname,
          },
        },
      };
    }

    case ADD_CHANGED_AGENT_ID_AGENCY_SCHEDULE: {
      return {
        ...state,
        changedIds: [...state.changedIds, payload.id],
      };
    }

    case REMOVE_CHANGED_AGENT_ID_AGENCY_SCHEDULE: {
      const filteredIds = state.changedIds.filter((id) => id !== payload.id);

      return {
        ...state,
        changedIds: filteredIds,
      };
    }

    case UPDATE_WORK_TIME_SETTINGS: {
      const entity = state.entities[payload.id];
      if (!entity) {
        return state;
      }

      const { schedule_events } = entity;
      const eventsLength = schedule_events.length;
      const firstEventFrom =
        eventsLength > 0
          ? +getNewDateFromString(schedule_events[0]?.from) +
            +new Date().getTimezoneOffset() * -1 * 60000
          : null;
      const lastEventTo =
        eventsLength > 0
          ? +getNewDateFromString(schedule_events[eventsLength - 1]?.to) +
            +new Date().getTimezoneOffset() * -1 * 60000
          : null;

      const workTimeAgentScheduleFrom = getUtcDateMillisecFromString(
        payload.workTimeSchedule.from,
        state.filterDate
      );
      const workTimeAgentScheduleTo = getUtcDateMillisecFromString(
        payload.workTimeSchedule.to,
        state.filterDate
      );

      const updatedAgentWorkTime = getUpdatedAgentWorkTime(
        firstEventFrom,
        lastEventTo,
        workTimeAgentScheduleFrom,
        workTimeAgentScheduleTo
      );

      const updatedCommonWorkTimes = getUpdatedCommonWorkTimes(
        state.workTimes,
        updatedAgentWorkTime
      );

      return {
        ...state,
        ...(updatedCommonWorkTimes
          ? { workTimes: updatedCommonWorkTimes }
          : {}),
        entities: {
          ...state.entities,
          [payload.id]: {
            ...entity,
            work_time_schedule: payload.workTimeSchedule,
            work_time: updatedAgentWorkTime,
          },
        },
      };
    }

    case DELETE_AGENT_AGENCY_SCHEDULE: {
      const updatedEntities = { ...state.entities };
      delete updatedEntities[payload.id];

      return {
        ...state,
        ids: state.ids.filter((id) => id !== payload.id),
        entities: updatedEntities,
      };
    }

    default:
      return state;
  }
};

export default agencyScheduleReducer;
