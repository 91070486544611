const getUtcDateMillisecFromString = (
  timeString: string,
  filteredDate?: number
): number => {
  const currentDate = filteredDate ? new Date(filteredDate) : new Date();

  const [hours, minutes] = timeString.split(":");

  currentDate.setUTCHours(parseInt(hours, 10));
  currentDate.setUTCMinutes(parseInt(minutes, 10));
  currentDate.setUTCSeconds(0);
  currentDate.setUTCMilliseconds(0);

  return currentDate.getTime();
};

export default getUtcDateMillisecFromString;
