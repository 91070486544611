import classModifier from "../../utils/classModifier";

import "./AuthPage.scss";
import logo from "../../icons/Gencytrack-01.png";

interface IAuthPage {
  title: string;
  description?: string;
  children: JSX.Element;
  isTextAutoHeight?: boolean;
}

const AuthPage = (props: IAuthPage) => {
  console.log("AuthPage Render");
  return (
    <div className="auth-page">
      <div className="auth-page__container">
        <header className="auth-page__header" style={{ textAlign: "center" }}>
          <div className="topbar__title" style={{ textAlign: "center" }}>
            <img src={logo} alt="" style={{ height: "28px", width: "auto" }} />
          </div>

          <h1 className="auth-page__title">{props.title}</h1>

          {props.description && (
            <div
              className={classModifier("auth-page__text", [
                !!props.isTextAutoHeight && "auto-height",
              ])}
            >
              {props.description}
            </div>
          )}
        </header>

        {props.children}
      </div>
    </div>
  );
};

export default AuthPage;
