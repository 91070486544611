import Pusher from "pusher-js";
import { pusherConfig } from "../config/socket-config";

const pusher = new Pusher("app-key", {
  cluster: pusherConfig.cluster,
  forceTLS: pusherConfig.forceTLS,
  wsHost: pusherConfig.wsHost,
  enabledTransports: ["ws"],
  userAuthentication: {
    transport: "ajax",
    endpoint: pusherConfig.userAuthentication.endpoint,
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  },
  channelAuthorization: {
    transport: "ajax",
    endpoint: pusherConfig.channelAuthorization.endpoint,
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  },
});

export default pusher;
