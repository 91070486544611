import { API_ROOT } from "../services/axiosInstance";

// const protocol = 'wss://';
// // const port = ':9502';
const currentHostname = window && window.location && window.location.hostname;
//
let test;

if (currentHostname === "localhost") {
  test = "track.local";
}
// else if (currentHostname === 'app.track-agent.agency') {
//   socketHostname = protocol + 'socket.track-agent.agency';
// }
else {
  test = currentHostname;
}
//test

interface PusherConfig {
  cluster: string;
  forceTLS: boolean;
  wsHost: string;
  wsPort: number;
  enabledTransports: string[];
  userAuthentication: {
    endpoint: string;
  };
  channelAuthorization: {
    endpoint: string;
  };
}
const socketHostname = "ws://18.192.172.166:6002";
export const SOCKET_ROOT = `${socketHostname}`;
export const pusherConfig: PusherConfig = {
  cluster: "",
  forceTLS: false,
  wsHost: test,
  wsPort: 6002,
  enabledTransports: ["ws"],
  userAuthentication: {
    endpoint: `${API_ROOT}auth/socket/set-user-connection`,
  },
  channelAuthorization: {
    endpoint: `${API_ROOT}auth/socket/registration-channel`,
  },
};
