import { Component } from "react";
import { connect } from "react-redux";

// import { SOCKET_ROOT } from '../config/socket-config';
import { setNotificationsCount } from "../redux/ducks/notifications";
// import { addAgent, addInvite, deleteInvite } from "../redux/ducks/agents";
// import { addAgency, deleteAgency } from "../redux/ducks/agencies";
// import { IInvite, IAgent, IAgency, IEvent } from "../interfaces/interfaces";
// import { setUser } from "../redux/ducks/user";
// import {
//   addEventAgentSchedule,
//   updateEventAgentSchedule,
//   deleteEventAgentSchedule,
//   addRequestAgentSchedule,
//   deleteRequestAgentSchedule,
// } from "../redux/ducks/agentSchedule";
// import {
//   addEventAgencySchedule,
//   updateEventAgencySchedule,
//   deleteEventAgencySchedule,
// } from "../redux/ducks/agencySchedule";
import { AppState } from "../redux/store";
import pusher from "../services/pusherInstance";

// @ts-ignore
// window.Pusher = require("pusher-js");

interface ISocketProps {
  userType: number;
  // addAgent: (agent: IAgent) => void;
  // addAgency: (agency: IAgency) => void;
  // addInvite: (invite: IInvite) => void;
  // deleteInvite: (id: number) => void;
  // deleteAgency: (id: number) => void;
  // setUser: (user: IAgent | IAgency) => void;
  // addNotification: (notification: any /*INotification*/) => void;
  // removeNotificationId: (id: number) => void;
  // addEventAgencySchedule: (
  //   event: IEvent,
  //   work_time: { from: number; to: number }
  // ) => void;
  // addEventAgentSchedule: (
  //   event: IEvent,
  //   work_time: { from: number; to: number }
  // ) => void;
  // updateEventAgentSchedule: (
  //   event: IEvent,
  //   work_time: { from: number; to: number }
  // ) => void;
  // updateEventAgencySchedule: (
  //   event: IEvent,
  //   work_time: { from: number; to: number }
  // ) => void;
  // deleteEventAgentSchedule: (
  //   event: IEvent,
  //   work_time: { from: number; to: number }
  // ) => void;
  // deleteEventAgencySchedule: (
  //   event: IEvent,
  //   work_time: { from: number; to: number }
  // ) => void;
  setNotificationsCount: (count: number) => void;
  // addRequestAgentSchedule: (
  //   event: IEvent,
  //   work_time: { from: number; to: number }
  // ) => void;
  // deleteRequestAgentSchedule: (
  //   event: IEvent,
  //   work_time: { from: number; to: number }
  // ) => void;
}

class Socket extends Component<ISocketProps> {
  componentDidMount() {
    console.log("-------------------------START------------------------------");
    this.setupSocket();
  }

  shouldComponentUpdate() {
    return false;
  }

  componentWillUnmount() {
    // const webSocket = this.state.ws;
    // webSocket.close();
  }

  setupSocket = () => {
    pusher.signin();
    pusher.connect();

    pusher.user.bind("test-event", (data: any) => {
      console.log(data);
    });

    pusher.bind("total-notification", (data: number) => {
      this.props.setNotificationsCount(data);
    });

    pusher
      .subscribe("private-channel." + 1)
      .bind("new-message", function (data: any) {
        console.log(data);
      });

    // // @ts-ignore
    // let channel = pusher.subscribe("presence-channel");
    // channel.bind("test-event", (data: any) => {
    //   console.log(data);
    // });
  };

  render() {
    return null;
  }
}

const mapStateToProps = (state: AppState) => ({
  userType: state.user.user.type,
});

const mapDispatchToProps = {
  setNotificationsCount,
};

export default connect(mapStateToProps, mapDispatchToProps)(Socket);
