import { Dispatch } from "redux";

import { IAction, IAgent, IAgency } from "../../interfaces/interfaces";
import { IUserState } from "./ducks.types";
import { getNotificationsCount } from "./notifications";

export const SET_TOKEN = "SET_TOKEN";

export const SET_USER = "SET_USER";

export const UPDATE_IMG = "UPDATE_IMG";

export const initApp =
  (user: IAgent | IAgency) => (dispatch: Dispatch<any>) => {
    const authToken = localStorage.getItem("token");

    dispatch({
      type: SET_TOKEN,
      payload: authToken,
    });

    dispatch({
      type: SET_USER,
      payload: user,
    });

    dispatch(getNotificationsCount());
  };

export const setUser = (user: IAgent | IAgency) => (dispatch: Dispatch) => {
  dispatch({
    type: SET_USER,
    payload: user,
  });
};

export const updateImg = (imgURL: string | null) => (dispatch: Dispatch) => {
  dispatch({
    type: UPDATE_IMG,
    payload: imgURL,
  });
};

const initialState: IUserState = {
  authToken: null,
  user: null,
};

const userReducer = (state = initialState, action: IAction) => {
  const { type, payload } = action;

  switch (type) {
    case SET_TOKEN: {
      return {
        ...state,
        authToken: payload,
      };
    }

    case SET_USER: {
      return {
        ...state,
        user: {
          ...payload,
        },
      };
    }

    case UPDATE_IMG: {
      return {
        ...state,
        user: {
          ...state.user,
          img: payload,
        },
      };
    }

    default:
      return state;
  }
};

export default userReducer;
