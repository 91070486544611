import { Dispatch } from "redux";

import API from "../../api/api";
import { IAgentScheduleState } from "./ducks.types";
import { IAction, IEvent } from "../../interfaces/interfaces";
import { CLOSE_MODAL, MODAL_TYPES } from "./activeWindows";
import isSameDay from "../../utils/isSameDay";

export const GET_AGENT_SCHEDULE = "GET_AGENT_SCHEDULE";

export const ADD_EVENT_AGENT_SCHEDULE = "ADD_EVENT_AGENT_SCHEDULE";
export const UPDATE_EVENT_AGENT_SCHEDULE = "UPDATE_EVENT_AGENT_SCHEDULE";
export const DELETE_EVENT_AGENT_SCHEDULE = "DELETE_EVENT_AGENT_SCHEDULE";

export const ADD_REQUEST_AGENT_SCHEDULE = "ADD_REQUEST_AGENT_SCHEDULE";
export const DELETE_REQUEST_AGENT_SCHEDULE = "DELETE_REQUEST_AGENT_SCHEDULE";
export const DELETE_REQUESTS_AGENT_SCHEDULE = "DELETE_REQUESTS_AGENT_SCHEDULE";

export const SET_PENDING_TRUE = "SET_PENDING_TRUE";

export const getAgentSchedule = (date: number) => (dispatch: Dispatch) => {
  dispatch({ type: SET_PENDING_TRUE });

  return API.getAgentSchedule(date)
    .then(({ data }) => {
      dispatch({
        type: GET_AGENT_SCHEDULE,
        payload: {
          date: date,
          events: data.schedule,
          requests: data.requests,
          workTime: data.date,
        },
      });
    })
    .catch(console.error);
};

export const addEventAgentSchedule =
  (event: IEvent, workTime: { from: number; to: number }) =>
  (dispatch: Dispatch) => {
    dispatch({
      type: ADD_EVENT_AGENT_SCHEDULE,
      payload: { event, workTime },
    });
  };

export const addRequestAgentSchedule =
  (event: IEvent, workTime: { from: number; to: number }) =>
  (dispatch: Dispatch) => {
    dispatch({
      type: ADD_REQUEST_AGENT_SCHEDULE,
      payload: { event, workTime },
    });
    dispatch({
      type: CLOSE_MODAL,
      payload: MODAL_TYPES.requestListModal,
    });
  };

export const updateEventAgentSchedule =
  (event: IEvent, workTime: { from: number; to: number }) =>
  (dispatch: Dispatch) => {
    dispatch({
      type: UPDATE_EVENT_AGENT_SCHEDULE,
      payload: { event, workTime },
    });
  };

export const deleteEventAgentSchedule =
  (event: IEvent, workTime: { from: number; to: number }) =>
  (dispatch: Dispatch) => {
    dispatch({
      type: DELETE_EVENT_AGENT_SCHEDULE,
      payload: { event, workTime },
    });
  };

export const deleteRequestAgentSchedule =
  (event: IEvent, workTime: { from: number; to: number }) =>
  (dispatch: Dispatch) => {
    dispatch({
      type: DELETE_REQUEST_AGENT_SCHEDULE,
      payload: {
        event,
        workTime,
      },
    });
    dispatch({
      type: CLOSE_MODAL,
      payload: MODAL_TYPES.requestListModal,
    });
  };

export const deleteRequestsAgentSchedule =
  (ids: number[]) => (dispatch: Dispatch) => {
    dispatch({
      type: DELETE_REQUESTS_AGENT_SCHEDULE,
      payload: {
        ids,
      },
    });
  };

const initialState: IAgentScheduleState = {
  date: 0,
  pending: true,
  events: [],
  requests: [],
  workTime: null,
};

const agentScheduleReducer = (state = initialState, action: IAction) => {
  const { type, payload } = action;

  switch (type) {
    case SET_PENDING_TRUE: {
      return {
        ...state,
        pending: true,
      };
    }

    case GET_AGENT_SCHEDULE: {
      return {
        ...state,
        date: payload.date,
        workTime: payload.workTime,
        events: payload.events,
        requests: payload.requests,
        pending: false,
      };
    }

    case ADD_EVENT_AGENT_SCHEDULE: {
      if (!isSameDay(state.date, payload.event.from)) {
        return state;
      }

      return {
        ...state,
        workTime: payload.workTime,
        events: [...state.events, payload.event],
      };
    }

    case ADD_REQUEST_AGENT_SCHEDULE: {
      if (!isSameDay(state.date, payload.event.from)) {
        return state;
      }

      return {
        ...state,
        workTime: payload.workTime,
        requests: [...state.requests, payload.event],
      };
    }

    case UPDATE_EVENT_AGENT_SCHEDULE: {
      if (!isSameDay(state.date, payload.event.from)) {
        return state;
      }

      return {
        ...state,
        workTime: payload.workTime,
        events: state.events?.map((event) =>
          event.id === payload.event.id ? payload.event : event
        ),
      };
    }

    case DELETE_EVENT_AGENT_SCHEDULE: {
      if (!isSameDay(state.date, payload.event.from)) {
        return state;
      }

      return {
        ...state,
        workTime: payload.workTime,
        events: state.events?.filter((event) => event.id !== payload.event.id),
      };
    }

    case DELETE_REQUEST_AGENT_SCHEDULE: {
      if (!isSameDay(state.date, payload.event.from)) {
        return state;
      }

      return {
        ...state,
        workTime: payload.workTime,
        requests: state.requests?.filter(
          (event) => event.id !== payload.event.id
        ),
      };
    }

    case DELETE_REQUESTS_AGENT_SCHEDULE: {
      const updatedRequests = state.requests.filter(
        (request) => !payload.ids.includes(request.id)
      );

      return {
        ...state,
        requests: updatedRequests,
      };
    }

    default:
      return state;
  }
};

export default agentScheduleReducer;
