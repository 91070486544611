import getNewDateFromString from "./getNewDateFromString";

const twentyFourHoursInMillis = 24 * 60 * 60 * 1000;

const isSameDay = (startOfDay: number, eventStart: string): boolean => {
  const endOfDay: number = startOfDay + twentyFourHoursInMillis;
  const eventStartMilsec: number = +getNewDateFromString(eventStart);

  return eventStartMilsec >= startOfDay && eventStartMilsec < endOfDay;
};

export default isSameDay;
